/*import Vue from 'vue';
import App from "./App.vue";
//import router from "./router";
///import VueRouter from "vue-router";
//import vuetify from "./plugins/vuetify";
import vuetify from "vuetify";
import VueMeta from 'vue-meta'

/*
const routes = [
  {
    path: "/",
    name: "Home-view",
    component: () => import(".//views/Home.vue"),
  },
  {
    path: "/course",
    name: "Course-view",
    component: () => import(".//views/Course.vue"),
  },
  {
    path: "/contact",
    name: "Contact-view",
    component: () => import(".//views/Contact.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});*/
/*
Vue.config.productionTip = false;
//export const changeRoute = new Vue(); //idk

//Vue.use(VueRouter);
Vue.use(VueMeta);
Vue.use(vuetify);
//Vue.use(router);

new Vue({
//    router: router,
    vuetify,
    render: (h) => h(App)
}).$mount("#app");
*/

import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueRouter from 'vue-router'

Vue.config.productionTip = false

const routes = [
  {
    path: "/",
    name: "Home-view",
    component: () => import(".//views/Home.vue"),
  },
  {
    path: "/course",
    name: "Course-view",
    component: () => import(".//views/Course.vue"),
  },
  {
    path: "/contact",
    name: "Contact-view",
    component: () => import(".//views/Contact.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

Vue.use(vuetify);
Vue.use(VueRouter);

new Vue({
  vuetify,
  router: router,
  render: h => h(App)
}).$mount('#app')