<template>
    <v-app style="background: #2b2b2b;">
        <v-toolbar color="accent" dark class="flex-grow-0">
            <v-btn class="mr-2"
                   text>
                <v-btn class="ma-0 pa-0 invisible no-text-transform"
                       to="/"
                       text>
                    <div class="d-flex align-center visible">
                        <v-img :src="images.logo"
                               class="mr-2"
                               contain
                               transition="scale-transition"
                               width="40" />
                        <v-toolbar-title>
                            Pianolespraktijk Liesbeth Versnel

                        </v-toolbar-title>
                    </div>
                </v-btn>
            </v-btn>
            <v-spacer></v-spacer>

            <v-btn to="/"
                   text
                   color=white
                   class="mr-2">
                HOME
            </v-btn>

            <v-btn to="/course"
                   text
                   color=white
                   class="mr-2">
                LESAANBOD
            </v-btn>
            <v-btn to="/contact"
                   text
                   color=white>
                CONTACT
            </v-btn>

        </v-toolbar>

        <v-main>
            <router-view />
        </v-main>
    </v-app>
</template>

<script>
export default {
  name: "App",

  metaInfo: {
    title: 'Pianoles Liesbeth Versnel',
    titleTemplate: '%s'
  },

  data: () => ({
    images:{
      logo: require('../src/assets/logo.svg')
    }
  }),
};
</script>

<style scoped>
    .invisible {
        visibility: hidden;
    }

    .visible {
        visibility: visible;
    }

    .no-text-transform {
        text-transform: none !important;
    }
</style>